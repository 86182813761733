import React from "react";
import "./EInvoice-key-features.css";
import checkMark from "../../assets/check-mark.png";

const EInvoiceKeyFeatures = () => {
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Key features
          </div>
          <div className="section-text2">Key features</div>
        </div>
      </div>

      <div className="EInvoice-key-features-bottom">
        <div className="key-feature-container">
          <div className="key-feature-top">01</div>
          <div className="key-feature-bottom">
            <div className="key-feature-bottom-top">
              <img src={checkMark} alt="FFF" />
              <div className="key-feature-bottom-top-text">
                Complete Invoice Management
              </div>
            </div>
            <div className="key-feature-bottom-bottom">
              Record all electronic invoices, manage customer accounts, send,
              and confirm them with the Egyptian Tax Authority
            </div>
          </div>
        </div>

        <div className="key-feature-container">
          <div className="key-feature-top">02</div>
          <div className="key-feature-bottom">
            <div className="key-feature-bottom-top">
              <img src={checkMark} alt="FFF" />
              <div className="key-feature-bottom-top-text">
                Seamless Integration
              </div>
            </div>
            <div className="key-feature-bottom-bottom">
              Follow the standards set by the Tax Authority, register customer
              data according to electronic tax systems, and use the unified item
              coding system
            </div>
          </div>
        </div>

        <div className="key-feature-container">
          <div className="key-feature-top">03</div>
          <div className="key-feature-bottom">
            <div className="key-feature-bottom-top">
              <img src={checkMark} alt="FFF" />
              <div className="key-feature-bottom-top-text">
                Data Import and Export
              </div>
            </div>
            <div className="key-feature-bottom-bottom">
              Import data via Excel sheets and send one or multiple invoices to
              the tax system. Review invoices according to the Tax Authority's
              standards before sending them
            </div>
          </div>
        </div>

        <div className="key-feature-container">
          <div className="key-feature-top">04</div>
          <div className="key-feature-bottom">
            <div className="key-feature-bottom-top">
              <img src={checkMark} alt="FFF" />
              <div className="key-feature-bottom-top-text">
                Full Invoice Processing
              </div>
            </div>
            <div className="key-feature-bottom-bottom">
              Register details of companies and individuals, and maintain item
              codes as required by the tax authority without changing existing
              barcodes.
            </div>
          </div>
        </div>

        <div className="key-feature-container">
          <div className="key-feature-top">05</div>
          <div className="key-feature-bottom">
            <div className="key-feature-bottom-top">
              <img src={checkMark} alt="FFF" />
              <div className="key-feature-bottom-top-text">
                Invoice Verification & Approval
              </div>
            </div>
            <div className="key-feature-bottom-bottom">
              Ensure electronic invoices are verified and approved by the Tax
              Authority. The system follows GS1 and EGS coding standards.
            </div>
          </div>
        </div>

        <div className="key-feature-container">
          <div className="key-feature-top">06</div>
          <div className="key-feature-bottom">
            <div className="key-feature-bottom-top">
              <img src={checkMark} alt="FFF" />
              <div className="key-feature-bottom-top-text">
                ERP Compatibility
              </div>
            </div>
            <div className="key-feature-bottom-bottom">
              Integrate with any other ERP system seamlessly
            </div>
          </div>
        </div>

        <div className="key-feature-container">
          <div className="key-feature-top">07</div>
          <div className="key-feature-bottom">
            <div className="key-feature-bottom-top">
              <img src={checkMark} alt="FFF" />
              <div className="key-feature-bottom-top-text">
                Support and Training
              </div>
            </div>
            <div className="key-feature-bottom-bottom">
              Receive technical support and training on how to use the
              e-invoicing software.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EInvoiceKeyFeatures;
