import React from "react";
import NavBar from "../../component/nav-bar/nav-bar";
import Footer from "../../component/footer/footer";
import PartnerBanner from "../../component/partner-banner/partner-banner";
import PartnerCards from "../../component/partner-cards/partner-cards";

const PartnersPage = () => {
  return (
    <div>
      <NavBar />
      <PartnerBanner />
      <PartnerCards />
      <Footer />
    </div>
  );
};

export default PartnersPage;
