import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ServiceCard from "../service-card/service-card";
import { services } from "../../utils/card-services";
import './services.css'

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
    AOS.refresh();
  }, []);
  return (
    <div className="section" id="services">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Services
          </div>
          <div className="section-text2">Services</div>
        </div>
      </div>

      <div className="service-cards-container">
        {services.map((service) => (
          <ServiceCard
            img={service.img}
            head={service.head}
            text={service.text}
            link={service.link}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
