import React, { useEffect, useState } from "react";

const CounterNumber = ({ end, duration, sign }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const incrementTime = duration / end;

    const timer = setInterval(() => {
      start += 1;
      setCount(start);

      if (start === end) {
        clearInterval(timer);
      }
    }, incrementTime);

    return () => clearInterval(timer); // Cleanup if the component unmounts
  }, [end, duration]);

  return (
    <div className="counter-number">
      {count} <span className="counter-span">{sign}</span>
    </div>
  );
};

export default CounterNumber;
