import React from "react";
import "./EReceipt-software2.css";
import userInterface from "../../assets/user-interface.png";
import softwareCost from "../../assets/softwareCost.png";
import efficiency from "../../assets/efficency.png";
import report from "../../assets/report.png";
import security from "../../assets/cyber-security.png";
import taxes from "../../assets/taxes.png";

const EReceiptSoftware2 = () => {
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Receipt software
          </div>
          <div className="section-text2">Receipt software</div>
        </div>
      </div>

      <div className="EReceipt-software2-bottom">
        <div className="EReceipt-software">
          <div className="EReceipt-software-img-container">
            <img
              className="EReceipt-software-bottom-img"
              src={userInterface}
              alt="FFF"
            />
            <div className="EReceipt-software-span">1</div>
          </div>
          <div className="EReceipt-software-bottom-text-container">
            <div className="EReceipt-software-bottom-head">
              User-Friendly Interface
            </div>
            <div className="EReceipt-software-bottom-text">
              Quickly create and send Receipts with an intuitive, easy-to-use
              interface
            </div>
          </div>
        </div>

        <div className="EReceipt-software">
          <div className="EReceipt-software-img-container">
            <img
              className="EReceipt-software-bottom-img"
              src={softwareCost}
              alt="FFF"
            />
            <div className="EReceipt-software-span">2</div>
          </div>
          <div className="EReceipt-software-bottom-text-container">
            <div className="EReceipt-software-bottom-head">
              Cost and Time Efficiency
            </div>
            <div className="EReceipt-software-bottom-text">
              Benefit from a cloud-based solution that reduces costs, minimizes
              errors, automates daily tasks, and lets you focus on business
              growth
            </div>
          </div>
        </div>

        <div className="EReceipt-software">
          <div className="EReceipt-software-img-container">
            <img
              className="EReceipt-software-bottom-img"
              src={efficiency}
              alt="FFF"
            />
            <div className="EReceipt-software-span">3</div>
          </div>
          <div className="EReceipt-software-bottom-text-container">
            <div className="EReceipt-software-bottom-head">
              Improved Work Efficiency
            </div>
            <div className="EReceipt-software-bottom-text">
              Effortlessly track Receipts and payments, and analyze sales data
              to enhance business performance
            </div>
          </div>
        </div>

        <div style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 1354 118" // Assuming the path's width is 1354 units
            preserveAspectRatio="none" // Optional: Makes the SVG stretch to fill the div without maintaining aspect ratio
            fill="none"
          >
            <path
              d="M1.17943 116.689L93.7934 28.6561C120.222 3.53463 163.763 13.9848 175.91 48.365V48.365C187.602 81.4537 228.719 92.7159 255.64 70.2033L303.712 30.003C340.867 -1.06831 395.566 1.24292 429.968 35.3377L456.302 61.4371C485.255 90.1314 533.165 85.4226 555.977 51.6405V51.6405C575.148 23.251 613.054 14.6001 642.644 31.8617L730.267 82.9791C764.205 102.777 807.271 96.8688 834.622 68.6623L857.983 44.5706C889.76 11.8002 944.798 22.5492 962.197 64.7505V64.7505C983.474 116.358 1056.47 117.334 1078.66 66.1145V66.1145C1098.24 20.9454 1159.67 14.5437 1188.14 54.707L1198.57 69.4252C1230.58 114.594 1299.19 109.338 1323.95 59.8199L1353.27 1.18091"
              stroke="#2B5996"
              stroke-width="2"
              stroke-dasharray="6 6"
            />
          </svg>
        </div>

        <div className="EReceipt-software">
          <div className="EReceipt-software-img-container">
            <img
              className="EReceipt-software-bottom-img"
              src={report}
              alt="FFF"
            />
            <div className="EReceipt-software-span">4</div>
          </div>
          <div className="EReceipt-software-bottom-text-container">
            <div className="EReceipt-software-bottom-head">
              Advanced Reporting
            </div>
            <div className="EReceipt-software-bottom-text">
              Easily collect, analyze, and organize financial data, track
              expenses, and generate accurate tax reports with a single click
            </div>
          </div>
        </div>

        <div className="EReceipt-software">
          <div className="EReceipt-software-img-container">
            <img
              className="EReceipt-software-bottom-img"
              src={security}
              alt="FFF"
            />
            <div className="EReceipt-software-span">5</div>
          </div>
          <div className="EReceipt-software-bottom-text-container">
            <div className="EReceipt-software-bottom-head">
              Security and Protection
            </div>
            <div className="EReceipt-software-bottom-text">
              Ensure your data is safeguarded with top-level security standards
            </div>
          </div>
        </div>

        <div className="EReceipt-software">
          <div className="EReceipt-software-img-container">
            <img
              className="EReceipt-software-bottom-img"
              src={taxes}
              alt="FFF"
            />
            <div className="EReceipt-software-span">6</div>
          </div>
          <div className="EReceipt-software-bottom-text-container">
            <div className="EReceipt-software-bottom-head">
              Tax Authority Integration
            </div>
            <div className="EReceipt-software-bottom-text">
              Directly send Receipts to the Egyptian Tax Authority, fully
              compliant with all regulations
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EReceiptSoftware2;
