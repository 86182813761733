import React from "react";
import NavBar from "../../component/nav-bar/nav-bar";
import Footer from "../../component/footer/footer";
import ChallengesBanner from "../../component/challenges-banner/challenges-bannes";
import ChallengesMain from "../../component/challenges-main/challenges-main";

const ChallengesPage = () => {
  return (
    <div>
      <NavBar />
      <ChallengesBanner />
      <ChallengesMain />
      <Footer />
    </div>
  );
};

export default ChallengesPage;
