import React from "react";
import NavBar from "../../component/nav-bar/nav-bar";
import Footer from "../../component/footer/footer";
import EReceiptBanner from "../../component/EReceipt-banner/EReceipt-banner";
import EReceiptSoftware from "../../component/EReceipt-software/EReceipt-software";
import EReceiptSoftware2 from "../../component/EReceipt-software2/EReceipt-software2";
import EReceiptApplication from "../../component/EReceipt-application/EReceipt-application";

const EReceiptPage = () => {
  return (
    <div>
      <NavBar />
      <EReceiptBanner />
      <EReceiptSoftware />
      <EReceiptSoftware2 />
      <EReceiptApplication />
      <Footer />
    </div>
  );
};

export default EReceiptPage;
