import React from "react";
import "./odoo-productivity.css";
import done from "../../assets/done.svg";
import productivityImg from '../../assets/productivityImg.png' 

const OdooProductivity = () => {
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Odoo Productivity Tools
          </div>
          <div className="section-text2">Odoo Productivity Tools</div>
        </div>
      </div>

      <div className="odoo-productivity-bottom">
        <div className="odoo-productivity-bottomLeft">
          <div className="odoo-productivities">
            <div className="odoo-productivity">
              <div className="productivity">
                <img className="produtivity-img" src={done} alt="FFF" />
                <div className="productivity-text">Sheet</div>
              </div>
              <div className="productivity">
                <img className="produtivity-img" src={done} alt="FFF" />
                <div className="productivity-text">Email Marketing</div>
              </div>
              <div className="productivity">
                <img className="produtivity-img" src={done} alt="FFF" />
                <div className="productivity-text">Survey</div>
              </div>
            </div>

            <div className="odoo-productivity">
              <div className="productivity">
                <img className="produtivity-img" src={done} alt="FFF" />
                <div className="productivity-text">Appointments</div>
              </div>
              <div className="productivity">
                <img className="produtivity-img" src={done} alt="FFF" />
                <div className="productivity-text">Mailing lists</div>
              </div>
              <div className="productivity">
                <img className="produtivity-img" src={done} alt="FFF" />
                <div className="productivity-text">Marketing Automation</div>
              </div>
            </div>
          </div>
          
        </div>
        <div>
            <img src={productivityImg} alt="FFF" className="odoo-productivity-img"/>
          </div>
      </div>
    </div>
  );
};

export default OdooProductivity;
