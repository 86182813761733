import Reaact from "react";
import "./oddoo-banner.css";

const OddooBanner = () => {
  return (
    <div className="oddoo-banner">
      <div className="oddoo-banner-content">
        <div className="oddoo-banner-text">
          Improve your business <br />
          with Odoo <span className="highlight">ERP solutions</span>
        </div>
        <div className="odoo-banner-subText">
          We specialize in integrating Odoo ERP with your website and <br />developing
          e-commerce platforms for seamless connectivity <br />Our customized
          solutions enhance customer experiences
        </div>
      </div>
    </div>
  );
};

export default OddooBanner;
