import React from "react";
import "./odoo-business2.css";
import odooBusiness2Img from "../../assets/odooBusiness2Img.png";
import businessAnalysis from "../../assets/busniessAnalysis.png";
import implementation from "../../assets/implementation.png";
import customization from "../../assets/customization.png";
import dataMigration from "../../assets/dataMigration.png";
import training from "../../assets/training.png";
import dataIntgration from "../../assets/dataIntgration.png";
import accountingServices from "../../assets/accountingServices.png";
import financialServices from "../../assets/financialServices.png";

const OdooBusiness2 = () => {
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Business Consultation
          </div>
          <div className="section-text2">Business Consultation </div>
        </div>
      </div>
      <div className="odoo-business2-bottom">
        <div className="odoo-business2-bottom-text_">
          As Odoo ERP experts, we optimize your business with custom solutions,
          including seamless integration with your website and e-commerce
          platforms. Our services enhance customer experiences, improve
          inventory management, and streamline sales. Partner with us to
          transform your website into a growth tool and drive innovation with
          Odoo ERP.
        </div>
        <div className="odoo-business2-bottom-img-container">
          <img
            src={odooBusiness2Img}
            alt="FFF"
            className="odoo-business2-img"
          />
        </div>
      </div>

      <div className="odoo-business2-bottomBottom-container">
        <div className="odoo-business2-bottomBottom">
          <div className="odoo-business2">
            <div className="odoo-business2-img-container">
              <img
                className="odoo-business2-bottom-img"
                src={businessAnalysis}
                alt="FFF"
              />
              <div className="odoo-business2-span">1</div>
            </div>
            <div className="odoo-business2-bottom-text-container">
              <div className="odoo-business2-bottom-head">
                Business Analysis
              </div>
              <div className="odoo-business2-bottom-text">
                Adapt these three columns to fit your design need. To duplicate,
                delete or move columns, select the column and use the top icons
                to perform your action
              </div>
            </div>
          </div>

          <div className="odoo-business2">
            <div className="odoo-business2-img-container">
              <img
                className="odoo-business2-bottom-img"
                src={implementation}
                alt="FFF"
              />
              <div className="odoo-business2-span">2</div>
            </div>
            <div className="odoo-business2-bottom-text-container">
              <div className="odoo-business2-bottom-head">Implementation</div>
              <div className="odoo-business2-bottom-text">
                The implementation journey starts with a thorough assessment of
                the customer’s needs, where the Odoo team collaborates with the
                customer to understand their business processes
              </div>
            </div>
          </div>

          <div className="odoo-business2">
            <div className="odoo-business2-img-container">
              <img
                className="odoo-business2-bottom-img"
                src={customization}
                alt="FFF"
              />
              <div className="odoo-business2-span">3</div>
            </div>
            <div className="odoo-business2-bottom-text-container">
              <div className="odoo-business2-bottom-head">Customization</div>
              <div className="odoo-business2-bottom-text">
                Odoo's experts customize and configure the software to align
                with the client's specific workflows and preferences
              </div>
            </div>
          </div>

          <div className="odoo-business2">
            <div className="odoo-business2-img-container">
              <img
                className="odoo-business2-bottom-img"
                src={dataMigration}
                alt="FFF"
              />
              <div className="odoo-business2-span">4</div>
            </div>
            <div className="odoo-business2-bottom-text-container">
              <div className="odoo-business2-bottom-head">Data Migration</div>
              <div className="odoo-business2-bottom-text">
                Odoo ensures a smooth data transition from old systems by
                carefully migrating customer records, inventory data, and
                financial transactions to the new system, keeping operations
                uninterrupted
              </div>
            </div>
          </div>

          <div className="odoo-business2">
            <div className="odoo-business2-img-container">
              <img
                className="odoo-business2-bottom-img"
                src={training}
                alt="FFF"
              />
              <div className="odoo-business2-span">5</div>
            </div>
            <div className="odoo-business2-bottom-text-container">
              <div className="odoo-business2-bottom-head">Training</div>
              <div className="odoo-business2-bottom-text">
                Training is provided to all users to help them smoothly adopt
                the new system. Odoo offers customized sessions based on each
                user’s role to make sure they can use the system effectively
              </div>
            </div>
          </div>

          <div className="odoo-business2">
            <div className="odoo-business2-img-container">
              <img
                className="odoo-business2-bottom-img"
                src={dataIntgration}
                alt="FFF"
              />
              <div className="odoo-business2-span">6</div>
            </div>
            <div className="odoo-business2-bottom-text-container">
              <div className="odoo-business2-bottom-head">Integration</div>
              <div className="odoo-business2-bottom-text">
                Flexe_Soft specializes in Odoo ERP systems integration services,
                seamlessly connecting them with core applications and databases
                to improve data flow and business processes
              </div>
            </div>
          </div>

          <div className="odoo-business2">
            <div className="odoo-business2-img-container">
              <img
                className="odoo-business2-bottom-img"
                src={accountingServices}
                alt="FFF"
              />
              <div className="odoo-business2-span">7</div>
            </div>
            <div className="odoo-business2-bottom-text-container">
              <div className="odoo-business2-bottom-head">
                Accounting Services
              </div>
              <div className="odoo-business2-bottom-text">
                Kamah Tech offers top-notch services for developing charts of
                accounts, setting up accounting systems, providing periodic
                reports, and training your staff
              </div>
            </div>
          </div>

          <div className="odoo-business2">
            <div className="odoo-business2-img-container">
              <img
                className="odoo-business2-bottom-img"
                src={financialServices}
                alt="FFF"
              />
              <div className="odoo-business2-span">8</div>
            </div>
            <div className="odoo-business2-bottom-text-container">
              <div className="odoo-business2-bottom-head">
                Financial Services
              </div>
              <div className="odoo-business2-bottom-text">
                Flexe_Soft provides tailored financial software solutions,
                including accounting, management systems, and analytics tools.
                Using advanced technology, they help businesses streamline
                operations, enhance accuracy, and drive growth.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OdooBusiness2;
