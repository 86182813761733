import React from "react";
import "./oddoo-sales.css";
import OddooSvgWithText from "../oddooSvgWithText/oddooSvgWithText";
import oddooSales from '../../assets/oddooSales.png'

const OddooSales = () => {
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Odoo sales Applications
          </div>
          <div className="section-text2">Odoo sales Applications</div>
        </div>
      </div>

      <div className="oddoo-sales">
        <div className="oddoo-sales-leftSide">
          <div className="oddoo-sales-container">
           <OddooSvgWithText text="Sales"/>
            <OddooSvgWithText text="CRM"/>
          </div>

          <div className="oddoo-sales-container">
           <OddooSvgWithText text="Invoicing"/>
            <OddooSvgWithText text="Sign"/>
          </div>

          <div className="oddoo-sales-container">
           <OddooSvgWithText text="Point of Sale"/>
            <OddooSvgWithText text="Subscriptions"/>
          </div>
        </div>
        <img src={oddooSales} className="oddoo-sales-img" alt="FFF"/>
      </div>
    </div>
  );
};

export default OddooSales;
