import React from "react";
import "./oddooSvgWithText.css";

const OddooSvgWithText = ({ text }) => {
  return (
    <div>
      <div className="svg-mobile-view">
        <svg
          class="odoo-svg"
          xmlns="http://www.w3.org/2000/svg"
          width="164"
          height="41"
          viewBox="0 0 275 64"
          fill="none"
        >
          <path
            d="M19.8348 1.94202C20.5577 0.737198 21.8597 0 23.2648 0H250.79C252.165 0 253.444 0.706754 254.176 1.87131L273.528 32.6578C274.414 34.0672 274.334 35.8778 273.328 37.204L254.2 62.4175C253.444 63.4144 252.265 64 251.014 64H23.0421C21.7594 64 20.5546 63.3849 19.8023 62.346L1.54909 37.1392C0.609533 35.8417 0.534692 34.1088 1.35888 32.7352L19.8348 1.94202Z"
            fill="#1A355A"
            fill-opacity="0.8"
          />
          <text
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
            fill="var(--white, #FFF)"
            font-family="IBM Plex Sans Arabic"
            font-size="24"
            font-weight="600"
          >
            {text}
          </text>
        </svg>
      </div>

      <div className="regular-oddo-svg">
      <svg
        class="odoo-svg"
        xmlns="http://www.w3.org/2000/svg"
        width="265"
        height="64"
        viewBox="0 0 275 64"
        fill="none"
      >
        <path
          d="M19.8348 1.94202C20.5577 0.737198 21.8597 0 23.2648 0H250.79C252.165 0 253.444 0.706754 254.176 1.87131L273.528 32.6578C274.414 34.0672 274.334 35.8778 273.328 37.204L254.2 62.4175C253.444 63.4144 252.265 64 251.014 64H23.0421C21.7594 64 20.5546 63.3849 19.8023 62.346L1.54909 37.1392C0.609533 35.8417 0.534692 34.1088 1.35888 32.7352L19.8348 1.94202Z"
          fill="#1A355A"
          fill-opacity="0.8"
        />
        <text
          x="50%"
          y="50%"
          dominant-baseline="middle"
          text-anchor="middle"
          fill="var(--white, #FFF)"
          font-family="IBM Plex Sans Arabic"
          font-size="24"
          font-weight="600"
        >
          {text}
        </text>
      </svg>
      </div>

    </div>
  );
};

export default OddooSvgWithText;
