import React from "react";
import "./odoo-solutions.css";
import checkMark from "../../assets/checkMark.svg";
import odooSolutionImg from "../../assets/odooSolutionImg.png";

const OdooSolutions = () => {
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Odoo Solutions & Modules
          </div>
          <div className="section-text2">Odoo Solutions & Modules </div>
        </div>
      </div>

      <div className="odoo-solutions-bottom">
        <div className="odoo-solutions-bottomLeft">
          <div className="odoo-solution">
            <img src={checkMark} alt="FFF" />
            <div className="odoo-solution-text">
              Odoo Consultations for Technical, Functional, and administration
            </div>
          </div>
          <div className="odoo-solution">
            <img src={checkMark} alt="FFF" />
            <div className="odoo-solution-text">
              Building, Implementing, Customizing, and integrating Odoo Modules
            </div>
          </div>

          <div className="odoo-solution">
            <img src={checkMark} alt="FFF" />
            <div className="odoo-solution-text">
              Odoo Data Integration with third-party applications
            </div>
          </div>

          <div className="odoo-solution">
            <img src={checkMark} alt="FFF" />
            <div className="odoo-solution-text">
              Odoo Trainings (Technical, Functional, Administration)
            </div>
          </div>

          <div className="odoo-solution">
            <img src={checkMark} alt="FFF" />
            <div className="odoo-solution-text">
              Odoo instant support and on-site support.
            </div>
          </div>
        </div>

        <div className="odoo-solution-bottomRight">
          <div className="oddo-solution-svg-conatiner">
            <div className="oddo-solution-svg-mobile-view">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="280"
                height="180"
                viewBox="0 0 280 180"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M208.796 62.0035C229.595 37.2451 238.961 16.1873 231.408 6.06389C218.07 -11.8132 157.019 11.1774 95.0479 57.415C93.5655 58.521 92.0959 59.6304 90.6397 60.7424C37.6757 63.5109 0 70.6054 0 78.9204C0 85.0055 20.1774 90.4368 51.758 93.9974C16.1226 128.408 -2.87528 160.283 6.98992 173.505C20.3282 191.382 81.3788 168.391 143.35 122.154C154.099 114.134 164.17 105.941 173.405 97.7835C234.596 95.7057 280 88.0485 280 78.9204C280 71.6637 251.305 65.3367 208.796 62.0035ZM208.796 62.0035C188.478 60.4103 165.005 59.501 140 59.501C122.626 59.501 105.991 59.94 90.6397 60.7424C76.2449 71.7355 63.1511 82.996 51.758 93.9974C75.8386 96.7124 106.549 98.3397 140 98.3397C151.512 98.3397 162.7 98.147 173.405 97.7835C187.262 85.5433 199.236 73.3825 208.796 62.0035Z"
                  fill="#B9ECFB"
                />
              </svg>
              <img
                src={odooSolutionImg}
                alt="FFF"
                className="odooSolution-img"
              />
            </div>
            <div
              className="oddo-solution-svg-regular-view"
              style={{ width: "100%", position: "relative" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 620 398"
                style={{ width: "100%", height: "auto" }} // Ensures SVG scales proportionally
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M462.333 137.293C508.388 82.4713 529.129 35.8434 512.404 13.4272C482.869 -26.1579 347.685 24.75 210.463 127.133C207.181 129.582 203.927 132.039 200.702 134.501C83.4248 140.631 0 156.34 0 174.752C0 188.226 44.6786 200.253 114.607 208.137C35.6998 284.331 -6.36688 354.911 15.4775 384.189C45.0123 423.774 180.196 372.866 317.418 270.483C341.22 252.724 363.52 234.583 383.968 216.521C519.462 211.92 620 194.964 620 174.752C620 158.684 556.461 144.674 462.333 137.293ZM462.333 137.293C417.344 133.766 365.367 131.752 310 131.752C271.528 131.752 234.693 132.724 200.702 134.501C168.828 158.843 139.835 183.777 114.607 208.137C167.928 214.149 235.931 217.752 310 217.752C335.492 217.752 360.265 217.325 383.968 216.521C414.651 189.417 441.166 162.49 462.333 137.293Z"
                  fill="#B9ECFB"
                />
              </svg>
              <img
                src={odooSolutionImg}
                alt="FFF"
                className="odooSolution-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OdooSolutions;
