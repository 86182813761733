import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import OddooPage from "./pages/oddoo/OddooPage";
import PartnersPage from "./pages/PartnersPage/PartnersPage";
import WhyChooseFlexePage from "./pages/WhyChooseFlexe/WhyChooseFlexePage";
import EInvoicePage from "./pages/E-invoicePage/E-invoicePage";
import EReceiptPage from "./pages/E-receiptPage/E-receiptPage";
import ChallengesPage from "./pages/challenges/challenges";
import ScrollToTop from "./component/scroll-to-top/scroll-to-top";

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
      <div>
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/oddoo" element={<OddooPage />}/>
          <Route path="/partners" element={<PartnersPage />}/>
          <Route path="/why-choose-flexe" element={<WhyChooseFlexePage />}/>
          <Route path="/EInvoice" element={<EInvoicePage />}/>
          <Route path="/EReceipt" element={<EReceiptPage />}/>
          <Route path="/challenges" element={<ChallengesPage />}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
