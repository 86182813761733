import React from "react";
import "./EReceipt-banner.css";

const EReceiptBanner = () => {
  return (
    <div className="EReceipt-banner">
      <div className="EReceipt-banner-content">
        <div className="EReceipt-text">Services</div>
        <div className="EReceipt-text2">Receipt software</div>
      </div>
    </div>
  );
};

export default EReceiptBanner;
