import React, { useEffect, useState } from "react";
import "./nav-bar.css";
import flexeLogo from "../../assets/flexe-logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";

const NavBar = () => {
  const [currentState, SetCurrentState] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState(!drawerState);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="drawer">
        <div
          onClick={() => {
            setDrawerState(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="black"
              fill-opacity="0.8"
            />
          </svg>
        </div>

        <div className="drawer-content">
          <div
            className="drawer-content-link"
            onClick={() => {
              setDrawerState(false);
              navigate("/");
            }}
          >
            Home Page
          </div>

          <a
            href="/#about-us"
            className="drawer-content-link"
            onClick={() => {
              setDrawerState(false);
            }}
          >
            About us
          </a>

          <div
            className="drawer-content-link"
            onClick={() => {
              setDrawerState(false);
              navigate("/why-choose-flexe");
            }}
          >
            Why choose flexe
          </div>

          <div className="drawer-content-compose">
            <div
              className="drawer-content-compose1"
              onClick={() => {
                setShowServices(!showServices);
              }}
            >
              <div className="drawer-content-compose1-text">Services</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M8.12498 9.00002L12.005 12.88L15.885 9.00002C16.275 8.61002 16.905 8.61002 17.295 9.00002C17.685 9.39002 17.685 10.02 17.295 10.41L12.705 15C12.315 15.39 11.685 15.39 11.295 15L6.70498 10.41C6.31498 10.02 6.31498 9.39002 6.70498 9.00002C7.09498 8.62002 7.73498 8.61002 8.12498 9.00002Z"
                  fill="black"
                  fill-opacity="0.8"
                />
              </svg>
            </div>
            {showServices ? (
              <div className="drawer-content-compose2">
                <div
                  className="drawer-content-compose2-text"
                  onClick={() => {
                    navigate("/EReceipt");
                  }}
                >
                  E-Receipt
                </div>
                <div
                  className="drawer-content-compose2-text"
                  onClick={() => {
                    navigate("/EInvoice");
                  }}
                >
                  E-Invoice
                </div>
                <div
                  className="drawer-content-compose2-text"
                  onClick={() => {
                    navigate("/oddoo");
                  }}
                >
                  Odoo
                </div>
              </div>
            ) : null}
          </div>
          <a
            className="drawer-content-link"
            href="/#partners"
            onClick={() => {
              setDrawerState(false);
            }}
          >
            Partners
          </a>
          <div
            className="drawer-content-link"
            onClick={() => {
              navigate("/challenges");
            }}
          >
            Challenges
          </div>
          <a className="drawer-content-link" href="/#contact-us">
            Contact us
          </a>
        </div>
      </div>
    </Box>
  );

  useEffect(() => {
    if (location.hash === "#partners") {
      const element = document.getElementById("partners");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else if (location.hash === "#contact-us") {
      const element = document.getElementById("contact-us");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else if (location.hash === "#about-us") {
      const element = document.getElementById("about-us");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else if (location.hash === "#services") {
      const element = document.getElementById("services");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div>
      <div className="nav-bar-mobile">
        <img
          src={flexeLogo}
          onClick={() => {
            navigate("/");
          }}
          className="nav-bar-mobile-logo"
        />
        <div
          className="nav-bar-mobile-hm-container"
          onClick={() => {
            setDrawerState(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18H21V16H9V18ZM3 6V8H21V6H3ZM9 13H21V11H9V13Z"
              fill="#333333"
            />
          </svg>
        </div>
      </div>
      <div className="nav-bar">
        <img
          className="nav-bar_logo"
          src={flexeLogo}
          onClick={() => {
            navigate("/");
          }}
        />
        <div className="nav-bar_content">
          <div
            className={`nav-bar_content__text ${
              currentState === "Home" ? "active" : ""
            }`}
            onClick={() => {
              SetCurrentState("Home");
              navigate("/");
            }}
          >
            Home
          </div>
          <div
            className={`nav-bar_content__text ${
              currentState === "About us" ? "active" : ""
            }`}
            onClick={() => {
              SetCurrentState("About us");
              navigate("/#about-us");
            }}
          >
            <div className="nav-link">About us</div>
          </div>
          <div
            className={`nav-bar_content__text ${
              currentState === "Why choose flexe" ? "active" : ""
            }`}
            onClick={() => {
              SetCurrentState("Why choose flexe");
              navigate("/why-choose-flexe");
            }}
          >
            Why choose flexe
          </div>
          <div className="nav-bar_content__composes">
            <div className="nav-bar_content__compose">
              <div
                className={`nav-bar_content__text ${
                  currentState === "Services" ? "active" : ""
                }`}
                onClick={() => {
                  SetCurrentState("Services");
                }}
              >
                Services
              </div>
              <div style={{ marginTop: "-3px" }}>
                <i
                  className={`${
                    currentState === "Services"
                      ? "arrow-active down"
                      : "arrow down"
                  }`}
                />
              </div>
            </div>
            <div className="nav-bar_content__services">
              <div
                className="service"
                onClick={() => {
                  navigate("/EReceipt");
                }}
              >
                E-Receipt
              </div>
              <div
                className="service"
                onClick={() => {
                  navigate("/EInvoice");
                }}
              >
                E-Invoicing
              </div>
              <div
                className="service"
                onClick={() => {
                  navigate("/oddoo");
                }}
              >
                Odoo
              </div>
            </div>
          </div>

          <div
            className={`nav-bar_content__text ${
              currentState === "Partners" ? "active" : ""
            }`}
            onClick={() => {
              SetCurrentState("Partners");
              navigate("/#partners");
            }}
          >
            <div className="nav-link">Partners</div>
          </div>
          <div
            className={`nav-bar_content__text ${
              currentState === "Challenges" ? "active" : ""
            }`}
            onClick={() => {
              SetCurrentState("Challenges");
              navigate("/challenges");
            }}
          >
            Challenges
          </div>
          <div
            className={`nav-bar_content__text ${
              currentState === "Contact us" ? "active" : ""
            }`}
            onClick={() => {
              SetCurrentState("Contact us");
              navigate("/#contact-us");
            }}
          >
            <div className="nav-link">Contact us</div>
          </div>
        </div>
      </div>

      <Drawer
        anchor="right"
        open={drawerState}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </div>
  );
};

export default NavBar;
