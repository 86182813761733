import React from "react";
import "./service-card.css";
import { useNavigate } from "react-router-dom";

const ServiceCard = ({ img, head, text, link }) => {
  const navigate = useNavigate();
  return (
    <div className="service-card" onClick={() => {
      navigate(`/${link}`)
    }}>
      <div className="service-card-imgContainer">
        <img src={img} alt="FFF" className="service-card-img" />
      </div>
      <div className="service-card-head">{head}</div>
      <div className="service-card-text">{text}</div>
    </div>
  );
};

export default ServiceCard;
