import React from "react";
import "./EInvoice-solution.css";
import solutionImg from '../../assets/EInvoiceSolution.png'

const EInvoiceSolution = () => {
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Solution Offers
          </div>
          <div className="section-text2">Solution Offers</div>
        </div>
      </div>

      <div className="EInvoice-solution-bottom">
        <img src={solutionImg} alt="FFF"/>
      </div>
    </div>
  );
};

export default EInvoiceSolution;
