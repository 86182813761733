import React from "react";
import './why-choose-banner.css'

const WhyChooseBanner = () => {
  return (
    <div className="why-choose-banner">
      <div className="why-choose-banner-content">
        <div className="why-choose-flexe-text">Why choose flexe</div>
      </div>
    </div>
  );
};

export default WhyChooseBanner;
