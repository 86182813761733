import React from "react";
import "./EInvoice-banner.css";

const EInvoiceBanner = () => {
  return (
    <div className="EInvoice-banner">
      <div className="EInvoice-banner-content">
        <div className="EInvoice-text">Services</div>
        <div className="EInvoice-text2">E-Invoicing</div>
      </div>
    </div>
  );
};

export default EInvoiceBanner;
