import React from "react";
import "./footer.css";
import SvgWithImg from "../svgWithImage/svgWithImage";
import icon from "../../assets/flexeIcon.svg";
import facebook from "../../assets/facebook.svg";
import whatsapp from "../../assets/whatsapp.svg";
import linkedin from "../../assets/linkedin.svg";
import youtube from "../../assets/youtube.svg";
import phone from "../../assets/footer-number.png";
import location from "../../assets/footer-location.png";
import copyRight from "../../assets/copyright.svg";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="footer-topContent">
        <div className="footer-section">
          <img className="flexe-icon" src={icon} alt="FFF"></img>
          <div className="footer-text">
            It is a leading information technology platform that combines
            innovation and quality to provide integrated software solutions that
            help develop businesses in various sectors
          </div>
          <div className="links-container">
            <Link
              to="https://www.facebook.com/profile.php?id=100090874748456&mibextid=ZbWKwL"
              target="_nsis"
            >
              <SvgWithImg img={facebook} />
            </Link>
            <SvgWithImg img={whatsapp} />
            <Link
              to="https://www.linkedin.com/company/flexe-soft/"
              target="_nsis"
            >
              <SvgWithImg img={linkedin} />
            </Link>
            <SvgWithImg img={youtube} />
          </div>
        </div>

        <div className="footer-section">
          <div className="links-header">Quick links</div>
          <div
            className="footer-link"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </div>
          <div
            className="footer-link"
            onClick={() => {
              navigate("/#about-us");
            }}
          >
            About us
          </div>
          <div
            className="footer-link"
            onClick={() => {
              navigate("/why-choose-flexe");
            }}
          >
            Why choose flexe
          </div>
          <div
            className="footer-link"
            onClick={() => {
              navigate("/#services");
            }}
          >
            Services
          </div>
          <div
            className="footer-link"
            onClick={() => {
              navigate("/#partners");
            }}
          >
            Partners
          </div>
          <div
            className="footer-link"
            onClick={() => {
              navigate("/challenges");
            }}
          >
            Challenges
          </div>
          <div
            className="footer-link"
            onClick={() => {
              navigate("/#contact-us");
            }}
          >
            Contact us
          </div>
        </div>
        <div className="footer-section">
          <div className="links-header">Contact us</div>
          <div className="footer-contactUs">
            <img src={phone} alt="FFF" />
            <div className="phoneNumbers-container">
              <div className="phoneNumber-container">
                <div className="phoneNumber">01127791586 (2+)</div>
                <div className="phoneNumber">01013409434 (2+)</div>
              </div>
              <div className="phoneNumber-container">
                <div className="phoneNumber">01127790498 (2+)</div>
                <div className="phoneNumber">0546223726</div>
              </div>
            </div>
          </div>

          <div className="footer-address">
            <img src={location} alt="FFF" />
            <div className="locations-container">
              <div className="location">
                Mohamed Kamel Hussein St. - New Nozha
              </div>
              <div className="location">9 Medina St. - Shubra El Khaymah</div>
              <div className="location">
                Muhammad Rashid Reda Street - South of Riyadh Casablanca -
                Kingdom of Saudi Arabia
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottomContent">
        <img src={copyRight} alt="FFF" />
        <div className="copyRight-text"> FLEXE SOFT@2023</div>
      </div>
    </div>
  );
};

export default Footer;
