import React, { useEffect } from "react";
import "./EInvoicing-section.css";
import AOS from "aos";
import "aos/dist/aos.css";
import EInvoicingImg from "../../assets/EInvoicingImg.png";

const EInvoicingSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
    AOS.refresh();
  }, []);

  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            E-Invoicing
          </div>
          <div className="section-text2">E-Invoicing</div>
        </div>
      </div>

      <div className="EInvoicing-bottom">
        <div className="EInvoicing-bottom-left">
          <div className="EInvoicing-bottom-text1">
            Comprehensive Accounting Solution Supporting E-Invoicing
          </div>
          <div className="EInvoicing-bottom-text2">
            Our electronic invoicing system records all commercial transactions
            between businesses or between businesses and individuals. It
            facilitates the preparation, electronic signing, sending, and
            receiving of invoices, ensuring their accuracy and authentication
            through the Egyptian Tax Authority. The flexe E-Invoice software
            supports issuing invoices in both Arabic and English, and allows for
            the modification of invoices and adjustment notifications for
            companies and individuals.
          </div>
        </div>
        <div className="EInvoicing-bottom-right">
          <img src={EInvoicingImg} alt="FFF" className="EInvoicing-bottom-right-img"/>
        </div>
      </div>
    </div>
  );
};

export default EInvoicingSection;
