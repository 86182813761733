import React from "react";
import "./partner-card.css";
import alpha from '../../assets/alpha logistics.png'

const PartnerCard = ({ img }) => {
  return (
    <div className="partner-card">
      <img src={img} alt="FFF" className="partner-card-img" />
    </div>
  );
};

export default PartnerCard;
