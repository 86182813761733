import React from "react";
import "./company-challenge.css";
import toyota from "../../assets/toyota.png";
import problem from "../../assets/problem.png";
import toyotaProblem from '../../assets/toyotaProblem.png'
import solution from '../../assets/solution.png'
import solutionContent from '../../assets/solutionContent.png'

const CompanyChallenge = ({companyImg, problemImg, solutionImg}) => {
  return (
    <div className="company-challenge-container">
      <img src={companyImg} alt="FFF" className="company-challenge-img"/>
      <div className="company-challenge-bottom">
        <div className="company-challenge-bottom-top">
          <div className="problem-container">
            <img src={problem} alt="FFF" className="problem-container-img"/>
            <div className="problem-text">Problem</div>
          </div>
          <div className="problem-content-container">
            <img src={problemImg} alt="FFF" className="problem-content-img"/>
          </div>
        </div>
        <div className="copmany-challenge-bottom-bottom">
            <div className="solution-container">
                <img src={solution} alt="FFF" className="solution-container-img"/>
                <div className="solution-text">Solution</div>
            </div>
            <div className="solution-content-container">
                <img src={solutionImg} alt="FFF" className="solution-content-img"/>
            </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyChallenge;
