import oddoo from "../assets/oddo.png";
import receipt from "../assets/receipt.png";
import invoice from "../assets/invoice.png";

export const services = [
  {
    img: oddoo,
    head: "Oddoo",
    text: "As experts in Odoo ERP, we are committed to optimizing your business operations with our customized solutions. We specialize in integrating Odoo ERP with your website and developing e-commerce platforms ",
    link: "oddoo"
  },
  {
    img: receipt,
    head: "E-Receipt",
    text: "Simplify your business with easy-to-use point of sale software. Manage sales, inventory and customer interactions Enjoy seamless electronic invoices and receipts.",
    link:"EReceipt"
  },
  {
    img: invoice,
    head: "E-Invoicing",
    text: "Our system records all business transactions, whether between companies or with individuals. It helps you create, sign, send and receive invoices electronically",
    link:"EInvoice"
  },
];
