import React from "react";
import "./partners.css";
import PartnerCard from "../partner-card/partner-card";
import Button from "../button/button";
import { useNavigate } from "react-router-dom";
import alpha from "../../assets/alpha logistics.png";
import bright from "../../assets/Bright Designs.png";
import cira from "../../assets/cira-logo.png";
import coop from "../../assets/COOP.png";
import egypac from "../../assets/EGYPAC.png";
import intepac from "../../assets/intepac.png";
import marbella from "../../assets/marbella.png";
import newfinepac from "../../assets/newfinepac.png";

const Partners = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/partners");
  };
  return (
    <div className="section" id="partners">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Partners
          </div>
          <div className="section-text2">Partners</div>
        </div>
      </div>

      <div className="partners-container">
        <PartnerCard img={alpha} />
        <PartnerCard img={bright} />
        <PartnerCard img={cira} />
        <PartnerCard img={coop} />
        <PartnerCard img={egypac} />
        <PartnerCard img={intepac} />
        <PartnerCard img={marbella} />
        <PartnerCard img={newfinepac} />
      </div>

      <div>
        <Button text="View All" withArrow={true} handleClick={handleClick} />
      </div>
    </div>
  );
};

export default Partners;
