import React, { useEffect, useRef, useState } from "react";
import "./counter.css";
import Group from "../../assets/Group.svg";
import Partners from "../../assets/partners.svg";
import CustomerSatsfiction from "../../assets/customer satisfaction.svg";
import Support from "../../assets/support.svg";
import CounterNumber from "../counter-number/counter-number";

const Counter = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing after the section is visible
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the section is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="counter-container" ref={sectionRef}>
      <div className="counter-content">
        <div className="counter">
          <div>
            <img className="counter-icon" src={Group}></img>
          </div>
          {isVisible && <CounterNumber end={250} duration={2000} sign="K" />}
          <div className="counter-text">Projects</div>
        </div>
        <div className="counter">
          <div>
            <img className="counter-icon" src={Partners}></img>
          </div>
          {isVisible && <CounterNumber end={2} duration={2000} sign="+" />}
          <div className="counter-text">Partners</div>
        </div>
        <div className="counter">
          <div>
            <img className="counter-icon" src={CustomerSatsfiction}></img>
          </div>
          {isVisible && <CounterNumber end={100} duration={2000} sign="%" />}
          <div className="counter-text">Customer satisfaction</div>
        </div>
        <div className="counter">
          <div>
            <img className="counter-icon" src={Support}></img>
          </div>
          {isVisible && <CounterNumber end={24} duration={2000} sign="H" />}
          <div className="counter-text">Expert support</div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
