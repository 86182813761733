import React, { useEffect } from "react";
import "./challenges-main.css";
import AOS from "aos";
import "aos/dist/aos.css";
import CompanyChallenge from "../company-challenge/company-challenge";
import toyotaImg from "../../assets/toyota.png";
import toyotaProblemImg from "../../assets/toyotaProblem.png";
import toyotaSolution from "../../assets/solutionContent.png";
import kayanImg from "../../assets/kayan.png";
import kayanProblem from "../../assets/kayanProblem.png";
import kayanSolution from "../../assets/kayanSolution.png";
import egyptAirImg from "../../assets/egyptAir.png";
import egyptAirProblem from "../../assets/egyptAirProblem.png";
import egyptAirSolution from "../../assets/egyptAirSolution.png";
import toyotaProblemMobile from "../../assets/toyotaProblemMobile.png";
import toyotaSolutionMobile from "../../assets/toyotaSolutionMobile.png";
import kayanProblemMobile from '../../assets/kayanProblemMobile.png'
import kayanSolutionMobile from '../../assets/kayanSolutionMobile.png'
import egyptAirProblemMobile from '../../assets/egyptAirProblemMobile.png'
import egyptAirSolutionMobile from '../../assets/egyptAirSolutionMobile.png'

const ChallengesMain = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
    AOS.refresh();
  }, []);

  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            The main issues
          </div>
          <div className="section-text2">Main issues</div>
        </div>
      </div>

      <div className="challenge-bottom">
        <div className="company-challenga-regularView">
          <CompanyChallenge
            companyImg={toyotaImg}
            problemImg={toyotaProblemImg}
            solutionImg={toyotaSolution}
          />
        </div>

        <div className="company-challenga-mobileView">
          <CompanyChallenge
            companyImg={toyotaImg}
            problemImg={toyotaProblemMobile}
            solutionImg={toyotaSolutionMobile}
          />
        </div>

        <div className="company-challenga-regularView">
          <CompanyChallenge
            companyImg={kayanImg}
            problemImg={kayanProblem}
            solutionImg={kayanSolution}
          />
        </div>

        <div className="company-challenga-mobileView">
          <CompanyChallenge
            companyImg={kayanImg}
            problemImg={kayanProblemMobile}
            solutionImg={kayanSolutionMobile}
          />
        </div>

        <div className="company-challenga-regularView">
          <CompanyChallenge
            companyImg={egyptAirImg}
            problemImg={egyptAirProblem}
            solutionImg={egyptAirSolution}
          />
        </div>

        <div className="company-challenga-mobileView">
          <CompanyChallenge
            companyImg={egyptAirImg}
            problemImg={egyptAirProblemMobile}
            solutionImg={egyptAirSolutionMobile}
          />
        </div>
      </div>
    </div>
  );
};

export default ChallengesMain;
