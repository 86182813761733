import React, { useEffect } from "react";
import "./EReceipt-software.css";
import AOS from "aos";
import "aos/dist/aos.css";
import EReceiptImg from "../../assets/EReceiptImg.png";

const EReceiptSoftware = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
    AOS.refresh();
  }, []);

  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Receipt software
          </div>
          <div className="section-text2">Receipt software</div>
        </div>
      </div>

      <div className="EReceipt-software-bottom">
        <div className="EReceipt-software-bottom-left">
          <div className="EReceipt-software-bottom-left-text1">
            
            <span className="software-text1-highlight">Receipt Software</span>
          </div>
          <div className="EReceipt-software-bottom-left-text2">
            Streamline your operations with the next-generation point-of-sale
            software designed to simplify sales, inventory management, and
            customer interactions, all in compliance with the Egyptian Tax
            Authority’s requirements. Achieve seamless integration with
            electronic invoicing and receipts
          </div>
        </div>
        <div className="EReceipt-software-bottom-right">
          <img src={EReceiptImg} alt="FFF" className="EReceipt-software-img"/>
        </div>
      </div>
    </div>
  );
};

export default EReceiptSoftware;
