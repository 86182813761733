import React from "react";
import "./EInvoice-basic-features.css";
import checkMark from "../../assets/done-basic-features.svg";

const EInvoiceBasicFeatures = () => {
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Basic features
          </div>
          <div className="section-text2">Basic features </div>
        </div>
      </div>

      <div className="EInvoice-basic-features-bottom">
        <div className="basic-feature-container">
          <img src={checkMark} alt="FFF" />
          <div className="basic-feature-text">
            Submit tax registration information
          </div>
        </div>

        <div className="basic-feature-container">
          <img src={checkMark} alt="FFF" />
          <div className="basic-feature-text">
            Monitor invoices on the portal in real-time
          </div>
        </div>

        <div className="basic-feature-container">
          <img src={checkMark} alt="FFF" />
          <div className="basic-feature-text">
            Download invoices in PDF format (Arabic/English)
          </div>
        </div>

        <div className="basic-feature-container">
          <img src={checkMark} alt="FFF" />
          <div className="basic-feature-text">
            Create Excel files for selected invoices
          </div>
        </div>

        <div className="basic-feature-container">
          <img src={checkMark} alt="FFF" />
          <div className="basic-feature-text">
            Download and store invoices within the program
          </div>
        </div>

        <div className="basic-feature-container">
          <img src={checkMark} alt="FFF" />
          <div className="basic-feature-text">
            Track and cancel invoices through the mobile app
          </div>
        </div>

        <div className="basic-feature-container">
          <img src={checkMark} alt="FFF" />
          <div className="basic-feature-text">
            Objection handling for purchase invoices via mobile app
          </div>
        </div>

        {/* <div className="basic-feature-container">
          <img src={checkMark} alt="FFF" />
          <div className="basic-feature-text">
            Create Excel files for selected invoices
          </div>
        </div> */}
        
      </div>
    </div>
  );
};

export default EInvoiceBasicFeatures;
