import React from "react";
import WhyChooseWorkFieldImg from "../../assets/whyChooseImg.png";
import "./why-choose-work-field.css";

const WhyChooseWorkField = () => {
  return (
    <div className="why-choose-work-field">
      <img src={WhyChooseWorkFieldImg} alt="FFF" className="why-choose-work-field-img"/>
    </div>
  );
};

export default WhyChooseWorkField;
