import React, { useEffect } from "react";
import Card from "../card/card";
import "./our-value.css";
import {
  GoalsPoints,
  MissionPoints,
  VissionPoints,
} from "../../utils/card-points";
import AOS from "aos";
import "aos/dist/aos.css";

const OurValue = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
    AOS.refresh();
  }, []);

  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Our Value
          </div>
          <div className="section-text2">Value</div>
        </div>
      </div>
      <div className="cards-container">
        <Card points={MissionPoints} header="Mission" />
        <Card points={VissionPoints} header="Vission" />
        <Card points={GoalsPoints} header="Goals" />
      </div>
    </div>
  );
};

export default OurValue;
