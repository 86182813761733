import React from "react";
import "./odoo-business1.css";

const OdooBusiness1 = () => {
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Business Consultation
          </div>
          <div className="section-text2">Business Consultation </div>
        </div>
      </div>
      <div className="odoo-business1-bottom">
        <div className="odoo-business1">
          Provide ERP, CRM, and Data Management consultations{" "}
        </div>
        <div className="odoo-business1">Provide deep business analysis</div>
        <div className="odoo-business1">Become your business, Advisor</div>

        <div className="odoo-business1">
          Give recommendations for your business
        </div>
        <div className="odoo-business1">
          Guide you to expand your business, and decrease operational costs
        </div>
      </div>
    </div>
  );
};

export default OdooBusiness1;
