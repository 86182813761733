import React from "react";
import "./button.css";

const Button = ({ text, withArrow, handleClick }) => {
  return (
    <button className="button" onClick={handleClick}>
      <div className="button-text">{text}</div>
      {withArrow ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
        >
          <path
            d="M16.5002 5.33325L14.6202 7.21325L22.0602 14.6666H5.8335V17.3333H22.0602L14.6202 24.7866L16.5002 26.6666L27.1668 15.9999L16.5002 5.33325Z"
            fill="white"
          />
        </svg>
      ) : null}
    </button>
  );
};

export default Button;
