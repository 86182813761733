import React from "react";
import "./EReceipt-application.css";
import EReceipt from "../../assets/EReceipt-receipt.png";
import QRCode from "../../assets/qr-code.png";
import seoReport from "../../assets/seo-report.png";
import accounting from "../../assets/accounting.png";
import paymentGateWay from "../../assets/payment-gateway.png";

const EReceiptApplication = () => {
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Application Features
          </div>
          <div className="section-text2"> Application Features</div>
        </div>
      </div>
      <div className="EReceipt-application-bottom">
        <div className="EReceipt-application-container">
          <div className="EReceipt-application-img-container">
            <img src={EReceipt} alt="FFF" />
          </div>
          <div className="EReceipt-application-text1">
            Custom Receipt Design
          </div>
          <div className="EReceipt-application-text2">
            Easily customize your receipts to fit your needs Add logos, fonts,
            and colors, and choose from different templates to save time and
            effort
          </div>
        </div>

        <div className="EReceipt-application-container">
          <div className="EReceipt-application-img-container">
            <img src={EReceipt} alt="FFF" />
          </div>
          <div className="EReceipt-application-text1">Smart Customer</div>
          <div className="EReceipt-application-text2">
            Create customer records, send payment reminders, and track each
            customer’s purchases over specified periods
          </div>
        </div>

        <div className="EReceipt-application-container">
          <div className="EReceipt-application-img-container">
            <img src={QRCode} alt="FFF" />
          </div>
          <div className="EReceipt-application-text1">
            QR Code for Each Receipt
          </div>
          <div className="EReceipt-application-text2">
            Generate QR codes for each receipt to streamline processing and
            verification
          </div>
        </div>

        <div className="EReceipt-application-container">
          <div className="EReceipt-application-img-container">
            <img src={seoReport} alt="FFF" />
          </div>
          <div className="EReceipt-application-text1">
            Detailed Sales and Purchase Reports
          </div>
          <div className="EReceipt-application-text2">
            View detailed sales and financial reports from any device. Easily
            track expenses, analyze data, and generate tax reports with one
            click
          </div>
        </div>

        <div className="EReceipt-application-container">
          <div className="EReceipt-application-img-container">
            <img src={accounting} alt="FFF" />
          </div>
          <div className="EReceipt-application-text1">
            Centralized Management
          </div>
          <div className="EReceipt-application-text2">
            Manage invoices for all your company branches from a single account
          </div>
        </div>

        <div className="EReceipt-application-container">
          <div className="EReceipt-application-img-container">
            <img src={paymentGateWay} alt="FFF" />
          </div>
          <div className="EReceipt-application-text1">
            Integration with Payment Gateways
          </div>
          <div className="EReceipt-application-text2">
            Connect with widely used payment gateways in the Egyptian market,
            including Oracle, SAP, Odoo, Dynamic, NextERP, and others in the
            future
          </div>
        </div>
      </div>
    </div>
  );
};

export default EReceiptApplication;
