import React from "react";

const SvgWithImg = ({img}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="53"
      viewBox="0 0 52 53"
      fill="none"
    >
      <circle cx="26" cy="26.4062" r="25" stroke="white" stroke-width="2" />

      <image
        href={img} // Use the imported PNG file here
        x="12"
        y="12"
        height="28"
        width="28"
      />
    </svg>
  );
};

export default SvgWithImg;
