import React from "react";
import NavBar from "../../component/nav-bar/nav-bar";
import Footer from "../../component/footer/footer";
import OddooBanner from "../../component/oddoo-banner/odoo-banner";
import OddooOpertional from "../../component/oddoo-opertional/oddoo-opertional";
import OddooSales from "../../component/oddoo-sales/oddoo-sales";
import OdooProductivity from "../../component/odoo-productivity/odoo-productivity";
import OdooWebSite from "../../component/odoo-webSite/odoo-webSite";
import OdooSolutions from "../../component/odoo-solutions/odoo-solutions";
import OdooBusiness1 from "../../component/odoo-business1/odoo-business1";
import OdooBusiness2 from "../../component/odoo-business2/odoo-business2";

const OddooPage = () => {
  return (
    <div>
      <NavBar />
      <OddooBanner />
      <OddooOpertional />
      <OddooSales />
      <OdooProductivity />
      <OdooWebSite />
      <OdooSolutions />
      <OdooBusiness1 />
      <OdooBusiness2 />
      <Footer />
    </div>
  );
};

export default OddooPage;
