import React from "react";
import "./challenges-banner.css";

const ChallengesBanner = () => {
  return (
    <div className="challenges-banner">
      <div className="challenges-banner-content">
        <div className="challenges-text">Challenges</div>
      </div>
    </div>
  );
};

export default ChallengesBanner;
