import React from "react";
import NavBar from "../../component/nav-bar/nav-bar";
import Footer from "../../component/footer/footer";
import WhyChooseBanner from "../../component/why-choose-banner/why-choose-banner";
import WhyChooseCards from "../../component/why-choose-cards/why-choose-cards";
import WhyChooseWorkField from "../../component/why-choose-work-field/why-choose-work-field";
import WhyChooseSolution from "../../component/why-choose-solution/why-choose-solution";

const WhyChooseFlexePage = () => {
  return (
    <div>
      <NavBar />
      <WhyChooseBanner />
      <WhyChooseCards />
      <WhyChooseWorkField />
      <WhyChooseSolution />
      <Footer />
    </div>
  );
};

export default WhyChooseFlexePage;
