import React, { useEffect } from "react";
import "./why-choose-solution.css";
import AOS from "aos";
import "aos/dist/aos.css";

const WhyChooseSolution = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
    AOS.refresh();
  }, []);
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Solution for different sectors
          </div>
          <div className="section-text2">Solution for different sectors</div>
        </div>
      </div>
      <div className="why-choose-solution-bottom">
        <div className="why-choose-solution-bottom-top">
          <div className="why-choose-soltion-container">
            <div className="why-choose-solution-span">
              <div className="why-choose-solution-regularView">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="48"
                  viewBox="0 0 109 72"
                  fill="none"
                >
                  <path
                    d="M0.719865 3.27727C-0.361842 1.97388 0.565101 0 2.25889 0H106.374C107.479 0 108.374 0.895431 108.374 2V70C108.374 71.1046 107.479 72 106.374 72H2.90908C1.11602 72 0.228868 69.8225 1.51143 68.5694L29.6794 41.0499C30.4246 40.3219 30.4861 39.1438 29.8208 38.3421L0.719865 3.27727Z"
                    fill="#2B5996"
                  />
                  <text
                    x="55%"
                    y="47%"
                    font-family="IBM Plex Sans Arabic"
                    font-size="32px"
                    fontWeight=" 600"
                    fill="#FFF"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    dominant-baseline="middle"
                    dy=".3em"
                  >
                    01
                  </text>
                </svg>
              </div>
              <div className="why-choose-solution-mobileView">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="25"
                  viewBox="0 0 42 25"
                  fill="none"
                >
                  <path
                    d="M1.32074 3.64788C0.0830839 2.38147 0.980339 0.25 2.7511 0.25H39.325C40.4296 0.25 41.325 1.14543 41.325 2.25V22.25C41.325 23.3546 40.4296 24.25 39.325 24.25H3.54297C1.67021 24.25 0.824645 21.9065 2.26595 20.7108L9.28299 14.8891C10.1824 14.1429 10.2532 12.7878 9.43633 11.952L1.32074 3.64788Z"
                    fill="#2B5996"
                  />
                  <text
                    x="55%"
                    y="42%"
                    font-family="IBM Plex Sans Arabic"
                    font-size="16px"
                    fontWeight=" 600"
                    fill="#FFF"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    dominant-baseline="middle"
                    dy=".3em"
                  >
                    01
                  </text>
                </svg>
              </div>
            </div>

            <div className="why-choose-solution">IT</div>
          </div>

          <div className="why-choose-soltion-container">
            <div className="why-choose-solution-span">
              <div className="why-choose-solution-regularView">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="48"
                  viewBox="0 0 109 72"
                  fill="none"
                >
                  <path
                    d="M0.719865 3.27727C-0.361842 1.97388 0.565101 0 2.25889 0H106.374C107.479 0 108.374 0.895431 108.374 2V70C108.374 71.1046 107.479 72 106.374 72H2.90908C1.11602 72 0.228868 69.8225 1.51143 68.5694L29.6794 41.0499C30.4246 40.3219 30.4861 39.1438 29.8208 38.3421L0.719865 3.27727Z"
                    fill="#2B5996"
                  />
                  <text
                    x="55%"
                    y="47%"
                    font-family="IBM Plex Sans Arabic"
                    font-size="32px"
                    fontWeight=" 600"
                    fill="#FFF"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    dominant-baseline="middle"
                    dy=".3em"
                  >
                    02
                  </text>
                </svg>
              </div>
              <div className="why-choose-solution-mobileView">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="25"
                  viewBox="0 0 42 25"
                  fill="none"
                >
                  <path
                    d="M1.32074 3.64788C0.0830839 2.38147 0.980339 0.25 2.7511 0.25H39.325C40.4296 0.25 41.325 1.14543 41.325 2.25V22.25C41.325 23.3546 40.4296 24.25 39.325 24.25H3.54297C1.67021 24.25 0.824645 21.9065 2.26595 20.7108L9.28299 14.8891C10.1824 14.1429 10.2532 12.7878 9.43633 11.952L1.32074 3.64788Z"
                    fill="#2B5996"
                  />
                  <text
                    x="55%"
                    y="42%"
                    font-family="IBM Plex Sans Arabic"
                    font-size="16px"
                    fontWeight=" 600"
                    fill="#FFF"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    dominant-baseline="middle"
                    dy=".3em"
                  >
                    02
                  </text>
                </svg>
              </div>
            </div>

            <div className="why-choose-solution">Retail</div>
          </div>

          <div className="why-choose-soltion-container">
            <div className="why-choose-solution-span">
              <div className="why-choose-solution-regularView">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="48"
                  viewBox="0 0 109 72"
                  fill="none"
                >
                  <path
                    d="M0.719865 3.27727C-0.361842 1.97388 0.565101 0 2.25889 0H106.374C107.479 0 108.374 0.895431 108.374 2V70C108.374 71.1046 107.479 72 106.374 72H2.90908C1.11602 72 0.228868 69.8225 1.51143 68.5694L29.6794 41.0499C30.4246 40.3219 30.4861 39.1438 29.8208 38.3421L0.719865 3.27727Z"
                    fill="#2B5996"
                  />
                  <text
                    x="55%"
                    y="47%"
                    font-family="IBM Plex Sans Arabic"
                    font-size="32px"
                    fontWeight=" 600"
                    fill="#FFF"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    dominant-baseline="middle"
                    dy=".3em"
                  >
                    03
                  </text>
                </svg>
              </div>
              <div className="why-choose-solution-mobileView">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="25"
                  viewBox="0 0 42 25"
                  fill="none"
                >
                  <path
                    d="M1.32074 3.64788C0.0830839 2.38147 0.980339 0.25 2.7511 0.25H39.325C40.4296 0.25 41.325 1.14543 41.325 2.25V22.25C41.325 23.3546 40.4296 24.25 39.325 24.25H3.54297C1.67021 24.25 0.824645 21.9065 2.26595 20.7108L9.28299 14.8891C10.1824 14.1429 10.2532 12.7878 9.43633 11.952L1.32074 3.64788Z"
                    fill="#2B5996"
                  />
                  <text
                    x="55%"
                    y="42%"
                    font-family="IBM Plex Sans Arabic"
                    font-size="16px"
                    fontWeight=" 600"
                    fill="#FFF"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    dominant-baseline="middle"
                    dy=".3em"
                  >
                    03
                  </text>
                </svg>
              </div>
            </div>

            <div className="why-choose-solution">Customized services</div>
          </div>
        </div>

        <div className="why-choose-solution-bottom-bottom">
          <div
            className="why-choose-soltion-container"
            style={{ width: "42%" }}
          >
            <div className="why-choose-solution-span">
              <div className="why-choose-solution-regularView">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="48"
                  viewBox="0 0 109 72"
                  fill="none"
                >
                  <path
                    d="M0.719865 3.27727C-0.361842 1.97388 0.565101 0 2.25889 0H106.374C107.479 0 108.374 0.895431 108.374 2V70C108.374 71.1046 107.479 72 106.374 72H2.90908C1.11602 72 0.228868 69.8225 1.51143 68.5694L29.6794 41.0499C30.4246 40.3219 30.4861 39.1438 29.8208 38.3421L0.719865 3.27727Z"
                    fill="#2B5996"
                  />
                  <text
                    x="55%"
                    y="47%"
                    font-family="IBM Plex Sans Arabic"
                    font-size="32px"
                    fontWeight=" 600"
                    fill="#FFF"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    dominant-baseline="middle"
                    dy=".3em"
                  >
                    04
                  </text>
                </svg>
              </div>
              <div className="why-choose-solution-mobileView">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="25"
                  viewBox="0 0 42 25"
                  fill="none"
                >
                  <path
                    d="M1.32074 3.64788C0.0830839 2.38147 0.980339 0.25 2.7511 0.25H39.325C40.4296 0.25 41.325 1.14543 41.325 2.25V22.25C41.325 23.3546 40.4296 24.25 39.325 24.25H3.54297C1.67021 24.25 0.824645 21.9065 2.26595 20.7108L9.28299 14.8891C10.1824 14.1429 10.2532 12.7878 9.43633 11.952L1.32074 3.64788Z"
                    fill="#2B5996"
                  />
                  <text
                    x="55%"
                    y="42%"
                    font-family="IBM Plex Sans Arabic"
                    font-size="16px"
                    fontWeight=" 600"
                    fill="#FFF"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    dominant-baseline="middle"
                    dy=".3em"
                  >
                    04
                  </text>
                </svg>
              </div>
            </div>

            <div className="why-choose-solution">government</div>
          </div>

          <div
            className="why-choose-soltion-container"
            style={{ width: "42%" }}
          >
            <div className="why-choose-solution-span">
              <div className="why-choose-solution-regularView">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="48"
                  viewBox="0 0 109 72"
                  fill="none"
                >
                  <path
                    d="M0.719865 3.27727C-0.361842 1.97388 0.565101 0 2.25889 0H106.374C107.479 0 108.374 0.895431 108.374 2V70C108.374 71.1046 107.479 72 106.374 72H2.90908C1.11602 72 0.228868 69.8225 1.51143 68.5694L29.6794 41.0499C30.4246 40.3219 30.4861 39.1438 29.8208 38.3421L0.719865 3.27727Z"
                    fill="#2B5996"
                  />
                  <text
                    x="55%"
                    y="47%"
                    font-family="IBM Plex Sans Arabic"
                    font-size="32px"
                    fontWeight=" 600"
                    fill="#FFF"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    dominant-baseline="middle"
                    dy=".3em"
                  >
                    05
                  </text>
                </svg>
              </div>
              <div className="why-choose-solution-mobileView">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="25"
                  viewBox="0 0 42 25"
                  fill="none"
                >
                  <path
                    d="M1.32074 3.64788C0.0830839 2.38147 0.980339 0.25 2.7511 0.25H39.325C40.4296 0.25 41.325 1.14543 41.325 2.25V22.25C41.325 23.3546 40.4296 24.25 39.325 24.25H3.54297C1.67021 24.25 0.824645 21.9065 2.26595 20.7108L9.28299 14.8891C10.1824 14.1429 10.2532 12.7878 9.43633 11.952L1.32074 3.64788Z"
                    fill="#2B5996"
                  />
                  <text
                    x="55%"
                    y="42%"
                    font-family="IBM Plex Sans Arabic"
                    font-size="16px"
                    fontWeight=" 600"
                    fill="#FFF"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    dominant-baseline="middle"
                    dy=".3em"
                  >
                    05
                  </text>
                </svg>
              </div>
            </div>

            <div className="why-choose-solution">Manufacturing</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseSolution;
