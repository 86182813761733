import React from "react";
import "./why-choose-cards.css";
import organisation from "../../assets/organisation.png";
import innovation from "../../assets/innovation.svg";

const WhyChooseCards = () => {
  return (
    <div className="why-choose-cards">
      <div className="why-choose-card">
        <div className="why-choose-card-svg-regularView">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="118"
            height="118"
            viewBox="0 0 118 118"
            fill="none"
          >
            <circle cx="59" cy="59" r="59" fill="#2B5996" />

            <image
              href={innovation} // Use the imported PNG file here
              x="31.5"
              y="31.5"
              height="55"
              width="55"
            />
          </svg>
        </div>
        <div className="why-choose-card-svg-mobileView">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="65"
            viewBox="0 0 64 65"
            fill="none"
          >
            <circle cx="32" cy="32.5" r="32" fill="#2B5996" />

            <image
              href={innovation} // Use the imported PNG file here
              x="8"
              y="8.5"
              height="48"
              width="48"
            />
          </svg>
        </div>

        <div className="why-choose-card-text-container">
          <div className="why-choose-card-head">
            Innovation in technical solutions
          </div>
          <div className="why-choose-card-text">
            FlexeSoft offers advanced IT solutions across various IT platforms.
          </div>
        </div>
      </div>
      <div className="why-choose-card">
        <div className="why-choose-card-svg-regularView">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="118"
            height="118"
            viewBox="0 0 118 118"
            fill="none"
          >
            <circle cx="59" cy="59" r="59" fill="#2B5996" />

            <image
              href={organisation} // Use the imported PNG file here
              x="31.5"
              y="31.5"
              height="55"
              width="55"
            />
          </svg>
        </div>

        <div className="why-choose-card-svg-mobileView">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="65"
            viewBox="0 0 64 65"
            fill="none"
          >
             <circle cx="32" cy="32.5" r="32" fill="#2B5996" />

            <image
              href={organisation} // Use the imported PNG file here
              x="8"
              y="8.5"
              height="48"
              width="48"
            />
          </svg>
        </div>

        <div className="why-choose-card-text-container">
          <div className="why-choose-card-head">
            Supporting organizations of all sizes
          </div>
          <div className="why-choose-card-text">
            FlexeSoft enables organizations to leverage their IT infrastructure
            to solve complex business needs
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseCards;
