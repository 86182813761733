import React from "react";
import PartnerCard from "../partner-card/partner-card";
import alpha from "../../assets/alpha logistics.png";
import bright from "../../assets/Bright Designs.png";
import cira from "../../assets/cira-logo.png";
import coop from "../../assets/COOP.png";
import egypac from "../../assets/EGYPAC.png";
import intepac from "../../assets/intepac.png";
import marbella from "../../assets/marbella.png";
import newfinepac from "../../assets/newfinepac.png";
import nov from "../../assets/NOV.png";
import sama from "../../assets/sama logo.png";
import synthomar from "../../assets/synthomer.png";
import total from "../../assets/total.png";
import toyota from "../../assets/toyota logos.png";
import behaira from "../../assets/البحيرة.png";
import arabia from "../../assets/العربية.png";
import alpha2 from "../../assets/الفا.png";
import elkabda from '../../assets/القابضة.png'
import elmaghrabi from '../../assets/المغربي_للبصريات.png'
import automotive from '../../assets/اوتوموتيف.png'
import bAuto from '../../assets/بى اوتو.png'
import elnahda from '../../assets/جامعة_النهضة_ببني_سويف.png'
import gamgom from '../../assets/جمجوم_فارما.png'
import geely from '../../assets/جيلي.png'
import sky from '../../assets/سكاي.png'
import kayan from '../../assets/كيان.png'
import misrPetroleum from '../../assets/مصر_للبترول.png'
import mmphis from '../../assets/ممفيس.png'

const PartnerCards = () => {
  return (
    <div
      className="partners-container"
      style={{ paddingLeft: "5%", paddingRight: "5%", marginTop: "4rem" }}
    >
      <PartnerCard img={alpha} />
      <PartnerCard img={bright} />
      <PartnerCard img={cira} />
      <PartnerCard img={coop} />
      <PartnerCard img={egypac} />
      <PartnerCard img={intepac} />
      <PartnerCard img={marbella} />
      <PartnerCard img={newfinepac} />
      <PartnerCard img={nov} />
      <PartnerCard img={sama} />
      <PartnerCard img={synthomar} />
      <PartnerCard img={total} />
      <PartnerCard img={toyota} />
      <PartnerCard img={behaira} />
      <PartnerCard img={arabia} />
      <PartnerCard img={alpha2} />
      <PartnerCard img={elkabda}/>
      <PartnerCard img={elmaghrabi}/>
      <PartnerCard img={automotive}/>
      <PartnerCard img={bAuto}/>
      <PartnerCard img={elnahda}/>
      <PartnerCard img={gamgom}/>
      <PartnerCard img={geely}/>
      <PartnerCard img={sky}/>
      <PartnerCard img={kayan}/>
      <PartnerCard img={misrPetroleum}/>
      <PartnerCard img={mmphis}/>
      
      
    </div>
  );
};

export default PartnerCards;
