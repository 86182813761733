import React from "react";
import "./odoo-webSite.css";
import odooWebSiteImg from '../../assets/odooWebSiteImg.png'

const OdooWebSite = () => {
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Odoo website application
          </div>
          <div className="section-text2">Odoo website application</div>
        </div>
      </div>

      <div className="odoo-webSite-bottom">
        <div className="odoo-webSite-text">
          Users are looking to consume engaging content. We empower our teams to
          create the most relevant content. We have one goal in mind, user
          satisfaction.
        </div>

        <img src={odooWebSiteImg} alt="FFF"/>
      </div>
    </div>
  );
};

export default OdooWebSite;
