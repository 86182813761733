import React, { useEffect } from "react";
import "./about-us.css";
import quality from "../../assets/quality.svg";
import customers from "../../assets/customers.svg";
import frameAboutUs from "../../assets/Frame-aboutUs.png";
import AOS from "aos";
import "aos/dist/aos.css";
const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
    AOS.refresh();
  }, []);

  return (
    <div className="section" id="about-us">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            About Us
          </div>
          <div className="section-text2">About Us</div>
        </div>
      </div>
      <div className="section-bottomContent">
        <div className="section-bottomContentLeft">
          <div className="section-bottomContentLeft_text1">
            we are committed to driving innovation in the software industry
          </div>

          <div className="section-bottomContentLeft_text2">
            Since our inception in 2020, we have been at the forefront of
            developing robust and scalable software solutions that enable
            businesses to stay ahead of the curve has propelled us to become a
            leading provider of Software solutions in the Middle East
          </div>

          <div className="section-bottomContentLeft_composes">
            <div className="section-bottomContentLeft_compose">
              <div className="section-bottomContentLeft_composeIcon">
                <img
                  src={quality}
                  className="section-bottomContentLeft_composeImg"
                  alt="FFF"
                />
              </div>
              <div className="section-bottomContentLeft_composeText">
                Focus on quality
              </div>
            </div>

            <div className="section-bottomContentLeft_compose">
              <div
                className="section-bottomContentLeft_composeIcon"
                style={{ backgroundColor: "#E9F9FE" }}
              >
                <img
                  src={customers}
                  className="section-bottomContentLeft_composeImg"
                  alt="FFF"
                />
              </div>
              <div className="section-bottomContentLeft_composeText">
                Focus on customer satisfaction
              </div>
            </div>
          </div>
        </div>
        <div className="section-bottomContentRight">
          <img src={frameAboutUs} alt="FFF" className="section-bottomContentRight-img"/>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
