import React from "react";
import "./contact-us.css";
import contactUs from "../../assets/contactUs.png";
import Button from "../button/button";

const ContactUs = () => {
  return (
    <div className="section" id="contact-us">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Contact us
          </div>
          <div className="section-text2">Contact us</div>
        </div>
      </div>

      <div className="contactUs-bottomContent">
        <div className="contactUs-bottomLeft">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="250"
            height="88"
            viewBox="0 0 326 88"
            fill="none"
          >
            <path
              d="M0 16C0 7.16344 7.16344 0 16 0H249.679C253.74 0 257.65 1.54424 260.614 4.31966L320.432 60.3197C331.025 70.2362 324.008 88 309.498 88H16C7.16343 88 0 80.8366 0 72V16Z"
              fill="#B9ECFB"
            />
            <foreignObject x="50" y="30" width="200" height="50">
              <div
                xmlns="http://www.w3.org/1999/xhtml"
                className="requestDemo-text"
              >
                Request A Demo
              </div>
            </foreignObject>
          </svg>

          <div className="form-container">
            <form className="contactUs-form">
              <div className="input-container">
                <label className="label">Full Name</label>
                <input className="input" placeholder="Enter Full Name" />
              </div>
              <div className="input-container">
                <label className="label">Email</label>
                <input className="input" placeholder="example@gmail.com" />
              </div>{" "}
              <div className="input-container">
                <label className="label">Phone number</label>
                <input className="input" placeholder="Enter phone number" />
              </div>
              <div className="input-container">
                <label className="label">Additional details</label>
                <textarea
                  className="input"
                  placeholder="Additional details"
                  style={{ height: "100px" }}
                />
              </div>
              <div className="input-container">
                <Button text="Send message" withArrow={false} />
              </div>
            </form>
          </div>
        </div>

        <div className="contactUs-bottomRight">
          <img src={contactUs} className="contactUs-img" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
