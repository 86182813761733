import React, { useEffect } from "react";
import "./oddoo-opertional.css";
import oddooApps from "../../assets/oddooApps.png";
import oddooApps2 from "../../assets/oddooApps2.png";
import AOS from "aos";
import "aos/dist/aos.css";

const OddooOpertional = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
    AOS.refresh();
  }, []);
  return (
    <div className="section">
      <div className="section-topContent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="4"
          viewBox="0 0 84 4"
          fill="none"
        >
          <path
            d="M2 2H82"
            stroke="#2B5996"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>

        <div className="section-text">
          <div
            className="section-text1"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            Odoo Operational Applications
          </div>
          <div className="section-text2">Odoo Operational Applications</div>
        </div>
      </div>

      <div className="oddoo-operations">
        <img src={oddooApps} className="oddoo-operation-img1" alt="FFF" />

        <img src={oddooApps2} alt="FFF" className="oddo-operation-img2" />
      </div>
    </div>
  );
};

export default OddooOpertional;
