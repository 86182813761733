import React from "react";
import Carousel1 from "../../assets/carousel 1.png";
import "./banner.css";
import BannerButton from "../banner-button/banner-button";
const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        <div className="banner-text">
          The future of technology <br />
          begins here with <span className="highlight">software</span>{" "}
          <br />
          <span className="highlight">solutions</span> in Egypt and the
          <br />
          Arab world
        </div>
        <BannerButton />
      </div>
    </div>
  );
};

export default Banner;
