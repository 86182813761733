export const MissionPoints = [
  "Our mission is to deliver high-quality software solutions that are tailored to meet the unique needs of each customer. to make a positive impact on the lives of our customers and the businesses they operate.",
];

export const VissionPoints = [
  "become leaders in the software solutions industry",
  "Providing flexible and innovative solutions to businesses across multiple industries",
  "Commitment to providing flexible and cost-effective solutions",
  "We are driven by our passion for innovation and concern for customer satisfaction",
];

export const GoalsPoints = [
  "To provide highquality and innovative software solutions for businesses across multiple industries. To deliver costeffective and flexible solutions that meet the evolving needs of our customers",
];
