import React, { useEffect, useRef, useState } from "react";
import NavBar from "../../component/nav-bar/nav-bar";
import Banner from "../../component/banner/banner";
import Counter from "../../component/counter/counter";
import AboutUs from "../../component/about-us/about-us";
import OurValue from "../../component/our-value/our-value";
import StageOfWork from "../../component/stage-of-work/stage-of-work";
import Services from "../../component/services/services";
import Partners from "../../component/partners/partners";
import ContactUs from "../../component/contact-us/contact-us";
import Footer from "../../component/footer/footer";

const HomePage = () => {
  

  return (
    <div>
      <NavBar />
      <Banner />
      <Counter/>
      <AboutUs />
      <OurValue />
      <StageOfWork />
      <Services />
      <Partners />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default HomePage;
