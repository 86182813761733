import React, { useEffect, useState } from "react";
import "./card.css";
import checkMark from "../../assets/checkMark.svg";
import checkMark2 from "../../assets/checkMark2.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Card = ({ points, header }) => {
  const [imgSrc, setImgSrc] = useState(checkMark);

  const handleMouseEnter = () => {
    setImgSrc(checkMark2);
  };

  const handleMouseLeave = () => {
    setImgSrc(checkMark2);
  };

  useEffect(() => {
    AOS.init({ duration: 500, once: false });
    AOS.refresh();
  }, []);

  return (
    <div
      className="card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-aos="zoom-in" // This will animate the text upwards
      
      data-aos-easing="ease-in-out"
    >
      <div className="card-head">
        <div className="card-text">{header}</div>
      </div>
      <div className="points-container">
        {points.map((point) => (
          <div className="point">
            <img className="point-checkMark" src={imgSrc} alt="FFF" />
            <div className="point-text">{point}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
