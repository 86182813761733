import React from "react";
import NavBar from "../../component/nav-bar/nav-bar";
import Footer from "../../component/footer/footer";
import EInvoiceBanner from "../../component/EInvoice-banner/EInvoice-banner";
import EInvoicingSection from "../../component/EInvoicing-section/EInvoicing-section";
import EInvoiceKeyFeatures from "../../component/EInvoice-key-features/EInvoice-key-features";
import EInvoiceBasicFeatures from "../../component/EInvoice-basic-features/EInvoice-basic-features";
import EInvoiceSolution from "../../component/EInvoice-solution/EInvoice-solution";

const EInvoicePage = () => {
  return (
    <div>
      <NavBar />
      <EInvoiceBanner />
      <EInvoicingSection />
      <EInvoiceKeyFeatures />
      <EInvoiceBasicFeatures />
      <EInvoiceSolution />
      <Footer />
    </div>
  );
};

export default EInvoicePage;
