import React from "react";
import "./banner-button.css";
import { useNavigate } from "react-router-dom";

const BannerButton = () => {
  const navigate = useNavigate();
  return (
    <div className="banner-button-container">
      <button
        className="banner-button"
        onClick={() => {
          navigate("/#about-us");
        }}
      >
        <div className="banner-button-text">Explore more</div>
        <i className="arrow-active2 down " style={{ marginBottom: "2px" }}></i>
      </button>
    </div>
  );
};

export default BannerButton;
