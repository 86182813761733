import React from "react";
import "./partner-banner.css";
import partnerIcon from "../../assets/partnerIcon.svg";
import PartnerCard from "../partner-card/partner-card";

const PartnerBanner = () => {
  return (
    <div className="partner-banner">
      <div className="partner-banner-content">
        <div className="partner-banner-header">Partners</div>
        <div className="partner-banner-text">
          More than <span className="highlight">200</span> clients have trusted
          our services and <br />
          programs with confidence over the past{" "}
          <span className="highlight">4 years</span>
        </div>

        <div className="partner-banner-container">
          <div className="partner-banner-img-container">
            <img src={partnerIcon} alt="FFF" />
            <div className="partner-text-container">
              <div className="partner-container-span">+200</div>
              <div className="partner-container-text">Partners</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default PartnerBanner;
